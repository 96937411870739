.ck-editor__editable {
    min-height: 10rem;
    border-radius: 10px;
    font-size: 1rem;
}

.ck.ck-content:not(.ck-style-grid__button__preview):not(.ck-editor__nested-editable) {
    padding: 1em 1.5em;
}

/* Make sure all content containers are distinguishable on a web page even of not focused. */
.ck.ck-content:not(:focus) {
    border: 1px solid var(--border-color);
}